<!-- Copyright 2023 (Unpublished) Verto Inc. -->
<fieldset>
  <legend *ngIf="item.listLabel">{{ item.listLabel }}</legend>
  <span class="p-label">
    <label [innerHTML]="itemLabelAsMarkdown">
      <span *ngIf="item.required" class="required-indicator">*</span>
    </label>
    <div [attr.data-name]="item.name" class="p-grid" style="margin-top: 7px">
      <div
        class="checkbox-option col-12 col-md-6"
        *ngFor="let option of options"
        [ngClass]="[item.horizontal ? 'p-col-' + item.colspanPerItem : 'p-col-12']"
      >
        <label class="radio-label" [ngClass]="{ optionSelected: item.formControl.value === option.value }">
          <p-radioButton
            [name]="item.id"
            [value]="option.value"
            [formControl]="item.formControl"
            (onClick)="emitEvent()"
          ></p-radioButton>
          <span [innerHTML]="option.label"></span>
        </label>
      </div>
    </div>
  </span>
</fieldset>
<app-form-validators [item]="item"></app-form-validators>

/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SmartOnFhirService } from '../services/smart-on-fhir.service';

@Component({
  template: ``,
  standalone: true,
})
export class CodeFlowComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _smartOnFhirService = inject(SmartOnFhirService);

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      const { launch, iss } = params;
      if (!launch || !iss) {
        return;
      }
      this._smartOnFhirService.launchFromEMR(launch, iss);
    });
  }
}

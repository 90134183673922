/* Copyright 2023 (Unpublished) Verto Inc. */

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ConfigBasedStorage } from './config-based-storage';
import { ShellLoader } from '../ShellLoader';
import { OAuthService } from 'angular-oauth2-oidc';
import { SHELL_DATA } from 'engage-common';

@Injectable()
export class SmartOnFhirService {
  launchedFrom = new BehaviorSubject<string | null>(null);

  refreshToken$ = new BehaviorSubject<string | null>(null);
  accessToken$ = new BehaviorSubject<string | null>(null);
  flowAccessToken$ = new BehaviorSubject<string | null>(null);
  decodedAccessToken$ = new BehaviorSubject<object | null>(null);
  storage = inject(ConfigBasedStorage);
  resourceServerUrl: string | null = null;
  shellData = inject(SHELL_DATA);

  periodicallyRefreshAccessToken = false;

  private _shellLoader = inject(ShellLoader);
  private _oauthService = inject(OAuthService);
  private httpClient = inject(HttpClient);

  constructor(private _http: HttpClient) {
    this._shellLoader.configLoaded.subscribe(() => {
      this.initialize();

      const smartOnFhir = this._shellLoader.config?.application?.smartOnFhirConfig;

      if (!smartOnFhir) {
        return;
      }

      const backendUrl = this._shellLoader.config.content.backendUrl;
      const discoveryUrl = `${backendUrl}/api/camh_dfd/v1/oauth/well_known?key=${this.shellData.appKey}`;

      this._oauthService.loadDiscoveryDocument(discoveryUrl);
    });
  }

  initialize() {
    this._refreshTokensFromStorage();

    this.launchedFrom.subscribe((launchedFrom) => {
      if (!launchedFrom) {
        return;
      }

      this.storage.setItem('sof_launched_from', launchedFrom);
    });

    this.refreshToken$.subscribe((refreshToken) => {
      if (!refreshToken) {
        return;
      }

      this.storage.setItem('sof_refresh_token', refreshToken);
    });

    this.accessToken$.subscribe((accessToken) => {
      if (!accessToken) {
        return;
      }

      this.storage.setItem('sof_access_token', accessToken);
    });

    this.decodedAccessToken$.subscribe((decodedAccessToken) => {
      if (!decodedAccessToken) {
        return;
      }

      this.storage.setItem('sof_decoded_token', JSON.stringify(decodedAccessToken));
    });

    this.flowAccessToken$.subscribe((flowAccessToken) => {
      if (!flowAccessToken) {
        return;
      }
      this.storage.setItem('sof_flow_access_token', flowAccessToken);
    });
  }

  attemptFlowAuthentication(): Observable<void | null> {
    const flowAuthCode = (location.search.split('?')[1]?.split('&') ?? [])
      .find((param) => {
        return param.split('=')[0] === 'flow_auth_code';
      })
      ?.split('=')?.[1];

    if (flowAuthCode) {
      return this._authenticateFromFlow(flowAuthCode);
    }

    return of(null);
  }

  hasExistingFlowLaunchContext(): boolean {
    this._refreshTokensFromStorage();

    const appHasOauthConfig =
      this._shellLoader.config?.application?.smartOnFhirConfig?.oauth_config;
    const hasFlowAccessToken = this.flowAccessToken$.value !== null;
    const hasProvider = this.providerID() !== null;
    const hasPatient = this.patientID() !== null;
    const hasEncounter = this.encounterID() !== null;

    return appHasOauthConfig && hasFlowAccessToken && hasProvider && hasPatient && hasEncounter;
  }

  private _authenticateFromFlow(flow_auth_code: string): Observable<void> {
    if (!flow_auth_code) {
      throw new Error(
        `Could not launch app with missing flow_auth_code parameter from Flow | flow_auth_code: ${flow_auth_code}`
      );
    }

    this._refreshTokensFromStorage();

    const flowAuthServer = this._shellLoader.config.application.smartOnFhirConfig.flowAuthServer;

    return this._http
      .post<any>(`${flowAuthServer}/api/v1/smart_on_fhir/access_token`, {
        flow_auth_code: flow_auth_code,
      })
      .pipe(
        tap((response) => {
          if (response.token && response.encounter && response.patient && response.user) {
            this._handleFlowAuth(
              response.token,
              response.encounter,
              response.patient,
              response.user
            );
          }
        })
      );
  }

  launchFromEMR(launch: string, iss: string): void {
    const backendUrl = this._shellLoader.config.content.backendUrl;
    const discoveryUrl = `${backendUrl}/api/camh_dfd/v1/oauth/well_known?key=${this.shellData.appKey}`;

    this._oauthService.loadDiscoveryDocument(discoveryUrl).then(() => {
      // redirect to oauth provider with authorization code request
      if (!launch || !iss) {
        this._oauthService.initCodeFlow();
      }

      this._oauthService.initCodeFlow('', { launch, iss, aud: iss });
    });
  }

  private _handleFlowAuth(token: string, encounterId: string, patientId: string, userId: string) {
    this.flowAccessToken$.next(token);
    this.decodedAccessToken$.next({ encounter: encounterId, patient: patientId, user: userId });
    this.launchedFrom.next('flow');
  }

  handleEMRRedirect(authCode: string): Promise<void> {
    const {
      oauth_config: { clientId, redirectUri },
    } = this._shellLoader.config.application.smartOnFhirConfig;

    const backendUrl = this._shellLoader.config.content.backendUrl;
    const discoveryUrl = `${backendUrl}/api/camh_dfd/v1/oauth/well_known?key=${this.shellData.appKey}`;

    return this._oauthService.loadDiscoveryDocument(discoveryUrl).then(() => {
      // the endpoint we discover from the discovery document
      const tokenEndpoint = this._oauthService.tokenEndpoint;


      const pckeVerifier = localStorage.getItem('PKCE_verifier') ?? sessionStorage.getItem('PKCE_verifier');
      const body = new URLSearchParams();
      body.set('code', authCode);
      body.set('code_verifier', pckeVerifier);
      body.set('grant_type', 'authorization_code');
      body.set('client_id', clientId);
      body.set('redirect_uri', redirectUri);

      this.httpClient
        .post(tokenEndpoint, body, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .subscribe((data) => {
          this.launchedFrom.next('emr');
          this.storage.setItem('token', data['access_token']);
          this.storage.setItem('access_token', data['access_token']);
          this.storage.setItem('expires_in', data['expires_in']);

          if (data['username']) {
            this.storage.setItem('username', data['username']);
          }

          const isJWT = data['access_token'].split('.').length === 3;
          if (isJWT) {
            const base64Url = data['access_token'].split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
              window
                .atob(base64)
                .split('')
                .map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
            );
            const decoded = JSON.parse(jsonPayload);
            this.decodedAccessToken$.next(decoded);
          }

          if (data['refresh_token']) {
            this.storage.setItem('refresh', data['refresh_token']);
            this.storage.setItem('refresh_token', data['refresh_token']);
            this.refreshToken$.next(data['refresh_token']);
            this._oauthService.setupAutomaticSilentRefresh();
          }

          this.accessToken$.next(data['access_token']);
        });
    });
  }

  enablePeriodicAccessTokenRefresh(time = 1000 * 60 * 3, callback: (newToken) => void) {
    if (this.periodicallyRefreshAccessToken === true) {
      return;
    }

    this.periodicallyRefreshAccessToken = true;
    setTimeout(() => {
      this._refreshAccessToken(time, callback);
    }, time);
  }

  private async _refreshAccessToken(time = 1000 * 60 * 3, callback: (newToken) => void) {
    if (this.periodicallyRefreshAccessToken === false) {
      setTimeout(() => {
        this._refreshAccessToken(time, callback);
      }, 1000 * 60);
    }

    const { access_token } = await this._oauthService.refreshToken();

    callback(access_token);
    this.accessToken$.next(access_token);

    setTimeout(() => {
      this._refreshAccessToken(time, callback);
    }, 1000 * 60);
  }

  private _refreshTokensFromStorage() {
    const launchedFrom = this.storage.getItem('sof_launched_from');
    if (launchedFrom !== null) {
      this.launchedFrom.next(launchedFrom);
    }

    const refreshToken = this.storage.getItem('sof_refresh_token');
    if (refreshToken !== null) {
      this.refreshToken$.next(refreshToken);
    }

    const accessToken = this.storage.getItem('sof_access_token');
    if (accessToken !== null) {
      this.accessToken$.next(accessToken);
    }
    const flowAccessToken = this.storage.getItem('sof_flow_access_token');
    if (flowAccessToken !== null) {
      this.flowAccessToken$.next(flowAccessToken);
    }

    const decodedAccessToken = this.storage.getItem('sof_decoded_token');
    if (decodedAccessToken !== null) {
      this.decodedAccessToken$.next(JSON.parse(decodedAccessToken));
    }
  }
  claims() {
    let claimsKey = this._shellLoader.config?.application?.smartOnFhirConfig?.claimsKey;
    if (claimsKey === undefined) {
      claimsKey = 'urn:com:cerner:authorization:claims';
    }

    return (
      this.decodedAccessToken$.value[claimsKey] || {
        user: this.decodedAccessToken$.value['user'],
        patient: this.decodedAccessToken$.value['patient'],
        encounter: this.decodedAccessToken$.value['encounter'],
      }
    );
  }

  username(): string | null {
    return this.storage.getItem('username');
  }

  providerID(): string | null {
    try {
      return this.claims().user;
    } catch {
      return null;
    }
  }

  patientID(): string | null {
    try {
      return this.claims().patient;
    } catch {
      return null;
    }
  }

  encounterID(): string | null {
    try {
      return this.claims().encounter;
    } catch {
      return null;
    }
  }
}

/* Copyright 2023 (Unpublished) Verto Inc. */

import { inject, Injectable } from '@angular/core';
import { ShellLoader } from '../ShellLoader';

@Injectable()
export class ConfigBasedStorage implements Storage {
  shellLoader = inject(ShellLoader);

  get delegate(): Storage {
    const storage = this.shellLoader.config?.application?.smartOnFhirConfig?.tokenStorage;

    if (storage === 'LocalStorage') {
      return localStorage;
    }

    if (storage === 'SessionStorage') {
      return sessionStorage;
    }

    // localStorage by default
    return sessionStorage;
  }

  get length(): number {
    return this.delegate.length;
  }

  clear(): void {
    this.delegate.clear();
  }

  getItem(key: string): string {
    return this.delegate.getItem(key);
  }

  key(index: number): string {
    return this.delegate.key(index);
  }

  removeItem(key: string): void {
    this.delegate.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.delegate.setItem(key, value);
  }
}

/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_KEY } from 'engage-common';
import { SmartOnFhirService } from '../services/smart-on-fhir.service';

@Component({
  template: ``,
  standalone: true,
})
export class AuthCodeToAccessTokenExchangeComponent implements OnInit {
  private _appKey: string = inject(APP_KEY);
  private _route = inject(ActivatedRoute);
  private _smartOnFhirService = inject(SmartOnFhirService);
  private _router = inject(Router);

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      if (!params['code']) {
        throw new Error('No code found in query params.');
      }

      this._smartOnFhirService.handleEMRRedirect(params['code']).then(() => {
        this._router.navigate([this._appKey]);
      });
    });
  }
}

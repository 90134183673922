/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, SecurityContext, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Components
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// Models
import { CamhDfdRadioButton } from '@shared/models/camh-dfd/radio-button';

@Component({
  selector: 'app-camhdfd-checkbox',
  templateUrl: './camh-dfd-radio-button.component.html',
  styleUrls: ['./camh-dfd-radio-button.component.scss'],
})
export class CamhDfdRadioButtonComponent extends ListFormComponentComponent {
  item: CamhDfdRadioButton;
  domSanitizer = inject(DomSanitizer);
  private _itemLabelMarkdown: SafeHtml;

  get itemLabelAsMarkdown() {
    if (this._itemLabelMarkdown) {
      return this._itemLabelMarkdown;
    }

    const safe = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.item.label
    );
    this._itemLabelMarkdown = this.domSanitizer.bypassSecurityTrustHtml(safe);
    return this._itemLabelMarkdown;
  }
}

/* Copyright 2023 (Unpublished) Verto Inc. */

// Shell Loader
import { ShellLoader } from './ShellLoader';

// Angular
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Configs
import { MicrofrontendConfigurationService } from './microfrontend-configuration.service';
import { ApplicationConfig, HTMLInjections } from './interfaces/shell-config';
import { Subscription } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { SmartOnFhirService } from './services/smart-on-fhir.service';
import { APP_BASE_HREF } from '@angular/common';

export let appKey = '';

export const shellDataToShare = {
  get appKey() {
    return appKey;
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  shellLoader = inject(ShellLoader);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _microfrontendConfigurationService = inject(MicrofrontendConfigurationService);
  private _oauthService = inject(OAuthService);
  smartOnFhirService = inject(SmartOnFhirService);

  baseHref = inject(APP_BASE_HREF);

  ngOnInit(): void {
    // Look for key param
    this.subscriptions = [this.parseAppKey()];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  parseAppKey(): Subscription {
    return this._route.queryParams.subscribe((params) => {
      if (Object.keys(params).length === 0 && location.search !== '') {
        return; // query params exist but engage hasn't picked it up yet.
      }

      if (!params.key) {
        // only parse in URL if there is no key param, for backwards compatibility
        this.parseAppKeyInUrl();
        return;
      }

      this.lookupAppConfig(params.key);
    });
  }

  parseAppKeyInUrl() {
    const path = location.pathname.split('/').filter((key) => {
      // filter out reverse proxy paths
      return !!key && key !== 'engage' && key !== 'generic-open-clinic';
    });
    let appKey = path[0];

    if (appKey === 'redirect' && path[1] !== undefined) {
      appKey = path[1];
    }

    if (!appKey) return;

    this.lookupAppConfig(appKey, () => {
      // if we're trying to access the oauth redirect component we should stop here
      if (appKey === 'redirect' && path[1] !== undefined) {
        return;
      }

      if (appKey === 'code-flow' && path[1] !== undefined) {
        return;
      }

      // in order to support visiting a nested path like http://engage.com/my-app/some/nested/path,
      // we must navigate to the entire path after the lazy module is loaded by lookupAppConfig
      this._router.navigateByUrl(path.join('/') + location.search);
    });
  }

  private _loadThirdPartyScripts(HTMLBodyInjection: HTMLInjections) {
    if (HTMLBodyInjection?.body) {
      document.getElementsByClassName('html-inject')[0].innerHTML = HTMLBodyInjection.body;
    }

    if (HTMLBodyInjection?.gtmScript) {
      let node = document.createElement('script');
      node.src = HTMLBodyInjection.gtmScript;
      node.type = 'text/javascript';
      node.async = true;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      document.getElementsByTagName('head')[0].appendChild(node);
    }

    if (HTMLBodyInjection?.gtagJsScript) {
      let node = document.createElement('script');
      node.src = HTMLBodyInjection.gtagJsScript;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    }

    if (HTMLBodyInjection?.stripeScript) {
      // script node for function
      let functionNode = document.createElement('script');
      functionNode.innerHTML = HTMLBodyInjection.stripeScript.function;
      // script node for stripe source script and rewardful attribute
      let scriptNode = document.createElement('script');
      scriptNode.async = true;
      scriptNode.src = HTMLBodyInjection.stripeScript.scriptSrc;
      scriptNode.setAttribute('data-rewardful', HTMLBodyInjection.stripeScript.dataRewardful);
      document.getElementsByTagName('head')[0].append(functionNode);
      document.getElementsByTagName('head')[0].append(scriptNode);
    }

    if (!HTMLBodyInjection?.astralAccessibilityScript) {
      const collection = document.getElementsByTagName('astral-accessibility');
      const script = document.getElementById('astral-accessibility-script');
      const init = document.getElementById('astral-accessibility-init');

      collection[0].remove();
      script.remove();
      init.remove();
    }

    if (HTMLBodyInjection.posthogScript) {
      this.injectPosthogScript(HTMLBodyInjection.posthogScript);
    }
  }

  get smartOnFhirConfig() {
    return this.shellLoader.config?.application?.smartOnFhirConfig;
  }

  lookupAppConfig(key: string, cb?: () => void): void {
    this._microfrontendConfigurationService
      .lookupConfig(key)
      .subscribe((application: ApplicationConfig) => {
        // todo: remove this
        (window as any).backendApiKey = key;

        appKey = key;

        if (application.config?.htmlInjections) {
          this._loadThirdPartyScripts(application.config.htmlInjections);
        }

        const shellConfig = { ...application.config, key: application.key };
        this.shellLoader.config = shellConfig;
        shellDataToShare['siteKey'] = this.shellLoader?.content?.siteKey;
        shellDataToShare['backendUrl'] = this.shellLoader?.content?.backendUrl;
        shellDataToShare['content'] = this.shellLoader?.content;

        const oauthConfig = this.smartOnFhirConfig?.oauth_config;
        // setup oauth service if it hasn't been already
        if (oauthConfig && this._oauthService.tokenEndpoint === null) {
          this._oauthService.configure(this.smartOnFhirConfig.oauth_config);
        }

        this.addTitleTagToDocument(this.shellLoader.config);

        this.shellLoader.init().then(() => {
          // attempts a launch from flow if possible. Calls cb() in either case.
          this.smartOnFhirService.attemptFlowAuthentication().subscribe(() => {
            cb?.();
          });
        });
      });
  }

  addTitleTagToDocument(shellConfig) {
    let titleNode = document.createElement('title');
    if (shellConfig?.content?.tabTitle) {
      titleNode.innerHTML = shellConfig?.content?.tabTitle;
    } else {
      titleNode.innerHTML = 'Verto Engage';
    }
    document.getElementsByTagName('head')[0].appendChild(titleNode);
  }

  injectPosthogScript({ apiKey, apiHost }: { apiKey: string; apiHost: string }) {
    if (document.getElementById('posthog-script')) return;

    const script = document.createElement('script');
    script.onload = () => {
      window['posthog'].init(apiKey, { api_host: apiHost });
    };
    script.onerror = () => {};
    script.src = `${this.baseHref}assets/shell/src/assets/scripts/posthog.min.js`;
    script.id = 'posthog-script';

    document.body.appendChild(script);
  }
}

/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, SecurityContext, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Components
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// Models
import { CamhDfdDropdown } from '@root/shared/models/camh-dfd/dropdown';

@Component({
  selector: 'app-camhdfd-dropdown',
  template: `
    <div class="p-fluid p-label" [attr.data-name]="item.name">
      <label [innerHTML]="itemLabelAsMarkdown" [attr.for]="item.id"
        ><span *ngIf="item.required" class="required-indicator">*</span></label
      >
      <select (input)="emitEvent()" [formControl]="item.formControl">
        <option *ngFor="let option of item.options" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <app-form-validators [item]="item"></app-form-validators>
  `,
  styles: [
    `
      select {
        width: 100%;
        padding: 8px 8px 8px 4px;
        border-radius: 4px;
        border: 1px solid #c0c0c0;
      }
    `,
  ],
})
export class CamhDfdDropdownComponent extends ListFormComponentComponent {
  item: CamhDfdDropdown;
  domSanitizer = inject(DomSanitizer);
  private _itemLabelMarkdown: SafeHtml;

  get itemLabelAsMarkdown() {
    if (this._itemLabelMarkdown) {
      return this._itemLabelMarkdown;
    }

    const safe = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.item.label
    );
    this._itemLabelMarkdown = this.domSanitizer.bypassSecurityTrustHtml(safe);
    return this._itemLabelMarkdown;
  }
}

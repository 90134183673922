import * as lodash from 'lodash';
import { FormElementImpl } from '@shared/models/form-element-impl';
import { Injectable } from '@angular/core';
import { ConditionExpression } from '../models/condition-expression';
@Injectable({
  providedIn: 'root',
})

/**
 * Service for evaluating logical expressions and conditions.
 */
export class LogicEvaluatorService {
  constructor() {}

  /**
   * Recursively evaluates a parsed logical condition expression.
   * @param parsedCondition The parsed condition expression to evaluate.
   * @param formValues The form values against which to evaluate the condition.
   * @returns true if the condition evaluates to true, false otherwise.
   */
  evaluateLogic(
    parsedCondition: string | ConditionExpression,
    formValues: FormElementImpl[]
  ): boolean {
    if (typeof parsedCondition === 'boolean') {
      return parsedCondition;
    } else if (typeof parsedCondition === 'string') {
      const evaluatedCondition = this.evaluateSingleCondition(
        parsedCondition,
        formValues
      );
      return evaluatedCondition;
    } else if (
      typeof parsedCondition === 'object' &&
      parsedCondition.operator &&
      parsedCondition.left &&
      parsedCondition.right !== undefined
    ) {
      const { left, operator, right } = parsedCondition;
      switch (operator) {
        case '&&':
          return (
            this.evaluateLogic(left, formValues) &&
            this.evaluateLogic(right, formValues)
          );
        case '||':
          return (
            this.evaluateLogic(left, formValues) ||
            this.evaluateLogic(right, formValues)
          );
        default:
          return this.evaluateSingleCondition(parsedCondition, formValues);
      }
    } else {
      console.error('Invalid parsed condition:', parsedCondition);
    }
  }

  /**
   * Evaluates a single logical condition.
   * @param condition The condition to evaluate.
   * @param formValues The form values against which to evaluate the condition.
   * @returns true if the condition evaluates to true, false otherwise.
   */
  private evaluateSingleCondition(
    condition: any,
    formValues: FormElementImpl[]
  ): boolean {
    if (
      typeof condition !== 'object' ||
      !condition.left ||
      !condition.operator ||
      condition.right === undefined
    ) {
      return;
    }

    const trimmedLhs = condition.left.trim().replace(/[()[]"']/g, '');
    const operator = condition.operator;
    const rhs = condition.right;

    const formElement = formValues.find((form) => form.name === trimmedLhs);

    if (!formElement) {
      return false;
    }

    const lhsValue = formElement.formControl.value;
    const rhsValue = !isNaN(Number(rhs)) ? Number(rhs) : rhs;

    switch (operator) {
      case '>=':
        return Number(lhsValue) >= rhsValue;
      case '<=':
        return Number(lhsValue) <= rhsValue;
      case '>':
        return Number(lhsValue) > rhsValue;
      case '<':
        return Number(lhsValue) < rhsValue;
      case '=':
        var result = lhsValue === rhs;
        if (typeof rhs === 'object') {
          result = lodash.isEqual(lodash.sortBy(rhs), lodash.sortBy(lhsValue));
        }
        return result;
      case '<>' || '!=':
        return lhsValue != rhs;
      case '!>>':
        if (rhs instanceof Object && lhsValue) {
          const intersection = lodash.intersection(lhsValue, rhs);
          if (rhs.length == 1) {
            return !lhsValue.some((num) => num == rhsValue);
          } else {
            return !(
              intersection.length > 0 && intersection.length === lhsValue.length
            );
          }
        }
        return true;
      case '>>':
        if (rhs instanceof Object && lhsValue) {
          const intersection = lodash.intersection(lhsValue, rhs);
          if (rhs.length == 1) {
            return lhsValue.some((num) => num == rhsValue);
          } else {
            return (
              intersection.length > 0 && intersection.length === lhsValue.length
            );
          }
        }
      default:
        return false;
    }
  }
}

<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<span class="p-label">
  <label [innerHTML]="item.label" [attr.for]="item.id"
    ><span *ngIf="item.required" class="required-indicator">*</span></label
  >
  <div [attr.data-name]="item.name" class="p-grid" style="margin-top: 7px">
    <div
      class="checkbox-option"
      *ngFor="let option of options"
      [ngClass]="[item.horizontal ? 'p-col-' + item.colspanPerItem : 'p-col-12']"
    >
      <p-radioButton
        [name]="item.id"
        [value]="option.value"
        [label]="option.label"
        [formControl]="item.formControl"
        (onClick)="emitEvent()"
      ></p-radioButton>
    </div>
  </div>
</span>
<app-form-validators [item]="item"></app-form-validators>

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { ShellLoader } from 'projects/shell/src/app/ShellLoader';

@Injectable()
export class SupersetService {
  shell = inject(ShellLoader);
  loaded = false;
  baseHref = inject(APP_BASE_HREF);

  get sdk(): any {
    if (!this.loaded) {
      throw new Error('Superset SDK is not loaded yet.');
    }

    return window['supersetEmbeddedSdk'];
  }

  loadSupersetScript() {
    if (this.loaded) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = () => {
        this.loaded = true;
        resolve(undefined);
      };
      script.onerror = () => {
        reject(undefined);
      };
      script.src = `${this.baseHref}assets/shell/src/assets/scripts/superset.min.js`;
      document.body.appendChild(script);
    });
  }

  embedDashboard(embeddingId: string, container: HTMLElement, guestToken: string) {
    // fd1dbd5e-571e-4215-ab7d-1eb00367b17e
    this.sdk.embedDashboard({
      id: embeddingId,
      supersetDomain: this.shell.content.supersetDomain,
      mountPoint: container,
      fetchGuestToken: () => guestToken,
      dashboardUiConfig: {
        hideTitle: true,
        hideChartControls: true,
        filters: {
          expanded: false,
          visible: false,
        },
      },
    });
  }
}

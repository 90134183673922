/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Markdown
import { Marked } from '@ts-stack/markdown';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { MarkdownText } from '@shared/models';

// Services
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormUpdateService } from '@root/services/form-update.service';

@Component({
  selector: 'app-markdown-text',
  templateUrl: './markdown-text.component.html',
  styleUrls: ['./markdown-text.component.scss'],
})
export class MarkdownTextComponent
  extends FormElementComponent
  implements OnChanges
{
  item: MarkdownText;

  html: SafeHtml;

  constructor(
    fgs: FormGroupService,
    fus: FormUpdateService,
    public fes: FormEventService,
    public sanitizer: DomSanitizer
  ) {
    super(fgs, fus, fes);
  }

  setHtml() {
    if (!this.item.markdownText) {
      return;
    }

    this.html = this.sanitizer.bypassSecurityTrustHtml(
      this.parseMarkdown(this.item.markdownText)
    );
  }

  ngOnInit(): void {
    this.setHtml();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue) {
      this.setHtml();
    }
  }

  parseMarkdown(text: string) {
    return Marked.parse(text);
  }
}

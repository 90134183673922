/* Copyright 2023 (Unpublished) Verto Inc. */

import { HostListener, Injectable, Injector, inject } from '@angular/core';
import { IPosthog } from './posthog.interfaces';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Injectable()
export class PosthogService {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _active = false;

  initialize() {
    if (this._active) return;
    this._active = true;

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._router.url)
      )
      .subscribe((path) => {
        this.capturePageView(path);
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    if (!this._active) return;
    this.capturePageLeave(this._activatedRoute.snapshot.title);
  }

  capture(metrics: IPosthog) {
    if (!this._active) return;
    if (!window['posthog']) return;
    window['posthog'].capture(metrics.eventName, metrics.fields);
  }

  /**
   * automatically $pageview events are captured only on page reload by posthog because
   * Angular is a SPA, so we use capturePageView to capture angular router page navigations
   */
  capturePageView(pageName: string) {
    if (!this._active) return;
    const pageViewMetric: IPosthog = { eventName: '$pageview', fields: { pageName: pageName } };
    this.capture(pageViewMetric);
  }

  capturePageLeave(pageName: string) {
    if (!this._active) return;
    const pageViewMetric: IPosthog = { eventName: '$pageleave', fields: { pageName: pageName } };
    this.capture(pageViewMetric);
  }
}
